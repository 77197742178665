import React from 'react';
//import { Router } from '@abyss/web/ui/Router';
import { FooterLinks } from '@hhs/ui/src/common/FooterLinks';
import { useStyles } from '@hhs/ui/old-abyss/ui/styles/hooks/useStyles';
import { styles } from './Content.styles';
import { Tranche1 } from './routes/Tranche1';
import { ContactUsCmn } from '../Footer/ContactUs/ContactUsCmn';
import { PrivacyPolicyCmn } from '../Footer/PrivacyPolicy/PrivacyPolicyCmn';
import { TermsOfUseCmn } from '../Footer/TermsOfUse/TermsOfUseCmn';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ApmRoutes } from '@elastic/apm-rum-react';

const Content = () => {
  const classes = useStyles(styles);

  return (
    <main id="main-content" className={classes.content}>
      <Router basename="/" title="{{title}} | CARES Provider Relief Fund">
        <ApmRoutes>
          <Route
            title="Contact Us"
            path="/contact-us"
            element={< ContactUsCmn />}
          />
          <Route
            title="Terms Of Use"
            path="/terms-of-use"
            element={< TermsOfUseCmn />}
          />
          <Route
            title="Privacy Policy"
            path="/privacy-policy"
            element={< PrivacyPolicyCmn />}
          />
          <Route
            title="Accessibility Statement"
            path="/accessibility-statement"
            element={<FooterLinks.AccessibilityStatement />}
          />
          <Route index path="/*" title="Relief Fund Request" element={<Tranche1 />} />
        </ApmRoutes>
      </Router>
    </main>
  );
};

export { Content };

import React, { useEffect } from 'react';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { Attestations } from './routes/Attestations';
import { ComeBackLater } from './routes/ComeBackLater';
import { BillingEntityConfirm } from './routes/BillingEntityConfirm';
import { TaxIdNumbers } from './routes/TaxIdNumbers';
import { MedicareVerification } from './routes/MedicareVerification';
import { AttestForm } from './routes/AttestForm';
import { Routes, Route} from "react-router-dom";

export const Tranche1 = () => {
  const router = useRouter();
  const location = router.getLocation();

  useEffect(() => {
    if (location.pathname === '/') {
      router.navigate('/step/1');
    }
  }, []);

  useEffect(() => {
    if (location.pathname === '/') {
      router.navigate('/step/1');
    }
  }, [location.pathname]);

  return (
      <Routes>
          <Route path="/" element={<BillingEntityConfirm />} />
          <Route path="/step/1" element={< BillingEntityConfirm />} />
          <Route path="/step/2" element={< TaxIdNumbers />} />
          <Route path="/step/3" element={< MedicareVerification />} />
          <Route path="/step/4" element={< Attestations />} />
          <Route path="/confirmation" element={< Attestations />} />
          <Route path="/attestation" element={<AttestForm/>} />
          <Route path="/not-eligible" element={< ComeBackLater />} />
      </Routes>
  );
};
